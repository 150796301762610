<template>
  <mobile-screen
    :header="true"
    :footer="false"
    screen-class="icon-app1 outlook-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_outlook',
            }"
          >
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("outlook", "outlook", "advanced-search") }}
        </div>
        <!-- <div class="component-title">
          {{
            displayLabelName("outlook", "advanced-search", `${selection}-first`)
          }}
        </div> -->
      </top-header-menu-wrapper>
    </template>
    <!-- <ul
      class="clebex-item-section tiny-top-spacing transparent no-border advanced-search"
    >
      <li class="clebex-item-section-item full-right-underline transparent">
        <div class="clebex-item-content-wrapper">
          <div class="selection-buttons-wrapper">
            <div class="selection-buttons" :class="selection">
              <button
                @click="selection = 'people'"
                class="body-btn"
                :class="{ selected: selection === 'people' }"
              >
                <icon icon="#cx-app1-login-page" width="28" height="28" />
              </button>
              <button
                @click="selection = 'time'"
                class="body-btn"
                :class="{ selected: selection === 'time' }"
              >
                <icon icon="#cx-pla1-hours-32x32" width="28" height="28" />
              </button>
              <button
                @click="selection = 'resource'"
                class="body-btn"
                :class="{ selected: selection === 'resource' }"
              >
                <icon icon="#cx-men1-services" width="30" height="30" />
              </button>
            </div>
          </div>
          <button class="body-btn one-click">
            <icon icon="#cx-men1-oneclick" width="30" height="30" />
          </button>
        </div>
      </li>
    </ul> -->
    <ul v-if="warningNumber" class="clebex-item-section tiny-top-spacing">
      <li class="clebex-item-section-item warning-center">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div class="warning-center">
                {{ displayLabelName("outlook", "outlook", "warning-center") }}
              </div>
            </span>
            <div class="warning-count-wrapper">
              <div class="warning-count-number">{{ warningNumber }}</div>
              <icon icon="#cx-men1-warning" width="10" height="10" />
            </div>
          </div>
        </div>
      </li>

      <li
        v-for="(warningMessage, i) in warningMessages"
        :key="i"
        class="clebex-item-section-item no-separator warning-line"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="red"
                icon="#cx-app1-warning-red"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>
                {{ warningMessage.message.replace(/.$/, "") }}:
                <span class="resource-name">{{
                  warningMessage.resourceName.join(", ")
                }}</span>
              </div>
            </label>
          </div>
        </div>
      </li>
      <!-- <li class="clebex-item-section-item no-separator warning-line">
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="warning"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>You have <span>3</span> unavailable attendees</div>
            </label>
          </div>
        </div>
      </li> -->
    </ul>
    <ul
      v-if="
        suggestedResources &&
          suggestedResources.levels &&
          suggestedResources.levels.length
      "
      class="clebex-item-section tiny-top-spacing location-wrapper"
    >
      <li class="clebex-item-section-item location-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "outlook", "location") }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <section class="levels">
        <li
          v-for="level in suggestedResources.levels.filter(
            (el) => el.is_default
          )"
          :key="level.id"
          @click="setLevelId(level.id)"
          class="clebex-item-section-item level"
        >
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-favourite-default"
                  width="20"
                  height="20"
                />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{
                  level.translations.find((el) => el.locale === locale)
                    ? level.translations.find((el) => el.locale === locale)
                        .attributes.name
                    : level.name
                }}{{ level.full_path.length ? "," : "" }}
                <span>{{ level.full_path.join(", ") }}</span
                ><icon
                  v-if="level.map_id"
                  style="width: 30px; height: 20px; display: flex;"
                  icon="#cx-app1-5d-maps"
              /></label>
            </div>
            <span v-if="levelIds.includes(level.id)" class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-checkmark" width="20" height="20" />
              </span>
            </span>
          </button>
        </li>
        <li
          v-for="level in suggestedResources.levels.filter(
            (el) => el.is_favorite && !el.is_default
          )"
          :key="level.id"
          @click="setLevelId(level.id)"
          class="clebex-item-section-item level"
        >
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-favourite-fill" width="20" height="20" />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{
                  level.translations.find((el) => el.locale === locale)
                    ? level.translations.find((el) => el.locale === locale)
                        .attributes.name
                    : level.name
                }}{{ level.full_path.length ? "," : "" }}
                <span>{{ level.full_path.join(", ") }}</span>
                <icon
                  v-if="level.map_id"
                  style="width: 30px; height: 20px; display: flex;"
                  icon="#cx-app1-5d-maps"
              /></label>
            </div>

            <span v-if="levelIds.includes(level.id)" class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-checkmark" width="20" height="20" />
              </span>
            </span>
          </button>
        </li>
        <li
          v-for="level in suggestedResources.levels.filter(
            (el) => !el.is_favorite && !el.is_default
          )"
          :key="level.id"
          @click="setLevelId(level.id)"
          class="clebex-item-section-item level"
        >
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-favourite" width="20" height="20" />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{
                  level.translations.find((el) => el.locale === locale)
                    ? level.translations.find((el) => el.locale === locale)
                        .attributes.name
                    : level.name
                }}{{ level.full_path.length ? "," : "" }}
                <span>{{ level.full_path.join(", ") }}</span
                ><icon
                  v-if="level.map_id"
                  style="width: 30px; height: 20px; display: flex;"
                  icon="#cx-app1-5d-maps"
              /></label>
            </div>
            <span v-if="levelIds.includes(level.id)" class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-checkmark" width="20" height="20" />
              </span>
            </span>
          </button>
        </li>
      </section>
    </ul>
    <ul
      v-if="
        suggestedResources &&
          suggestedResources.resource_types &&
          suggestedResources.resource_types.length
      "
      class="clebex-item-section tiny-top-spacing types-wrapper"
    >
      <li class="clebex-item-section-item types-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName(
                    "outlook",
                    "advanced-search",
                    "resource-types"
                  )
                }}
              </div>
            </span>
            <div
              v-if="resourceTypeIds.length"
              class="attendees-warning-wrapper"
            >
              <div class="warning-count-wrapper">
                <div class="warning-count-number">
                  {{ resourceTypeIds.length }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item types">
        <div class="clebex-item-content-wrapper">
          <div
            v-for="resourceType in suggestedResources.resource_types.filter(
              (el) => resourceTypeIds.includes(el.id)
            )"
            :key="resourceType.id"
            class="type selected"
          >
            <icon
              @click="setResourceTypeId(resourceType.id)"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="type-title">
              {{
                resourceType.translations.find((el) => el.locale === locale)
                  ? resourceType.translations.find((el) => el.locale === locale)
                      .attributes.name
                  : resourceType.name
              }}
            </div>
          </div>
        </div>
        <div class="clebex-item-content-wrapper">
          <div
            v-for="resourceType in suggestedResources.resource_types.filter(
              (el) => !resourceTypeIds.includes(el.id)
            )"
            :key="resourceType.id"
            @click="setResourceTypeId(resourceType.id)"
            class="type"
          >
            <div class="type-title">
              {{
                resourceType.translations.find((el) => el.locale === locale)
                  ? resourceType.translations.find((el) => el.locale === locale)
                      .attributes.name
                  : resourceType.name
              }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul
      v-if="
        suggestedResources &&
          suggestedResources.resource_attributes &&
          suggestedResources.resource_attributes.length
      "
      class="clebex-item-section tiny-top-spacing attributes-wrapper"
    >
      <li class="clebex-item-section-item attributes-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName(
                    "outlook",
                    "advanced-search",
                    "resource-attributes"
                  )
                }}
              </div>
            </span>
            <div
              v-if="resourceAttributeIds.length"
              class="attendees-warning-wrapper"
            >
              <div class="warning-count-wrapper">
                <div class="warning-count-number">
                  {{ resourceAttributeIds.length }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item attributes">
        <div class="clebex-item-content-wrapper">
          <div
            v-for="resourceAttribute in suggestedResources.resource_attributes.filter(
              (el) => resourceAttributeIds.includes(el.id)
            )"
            :key="resourceAttribute.id"
            class="attribute selected"
          >
            <icon
              @click="setResourceAttributeId(resourceAttribute.id)"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="attribute-title">
              {{
                resourceAttribute.translations.find(
                  (el) => el.locale === locale
                )
                  ? resourceAttribute.translations.find(
                      (el) => el.locale === locale
                    ).attributes.name
                  : resourceAttribute.name
              }}
            </div>
          </div>
        </div>
        <div class="clebex-item-content-wrapper">
          <div
            v-for="resourceAttribute in suggestedResources.resource_attributes.filter(
              (el) => !resourceAttributeIds.includes(el.id)
            )"
            :key="resourceAttribute.id"
            @click="setResourceAttributeId(resourceAttribute.id)"
            class="attribute"
          >
            <div class="attribute-title">
              {{
                resourceAttribute.translations.find(
                  (el) => el.locale === locale
                )
                  ? resourceAttribute.translations.find(
                      (el) => el.locale === locale
                    ).attributes.name
                  : resourceAttribute.name
              }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing capacity-wrapper">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("outlook", "advanced-search", "capacity") }}
            </label>
            <input type="number" :value="liveCapacity" @input="onInputChange" />
          </div>
          <span class="follow-up-icons">
            <span @click="decreaseCapacity()" class="follow-up-icon-item">
              -
            </span>
            <span @click="increaseCapacity()" class="follow-up-icon-item">
              +
            </span>
          </span>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing transparent no-border">
      <li
        class="clebex-item-section-item full-right-underline transparent advanced-search"
      >
        <button
          @click="goTo5DMap()"
          class="body-btn"
          :disabled="!selectedMapId"
        >
          <icon icon="#cx-app1-5d-maps" width="20" height="20" />
          {{ displayLabelName("outlook", "outlook", "5d-map") }}
        </button>
      </li>
    </ul>
    <ul
      v-if="suggestedResourcesList && suggestedResourcesList.length"
      class="clebex-item-section tiny-top-spacing suggested-resources"
    >
      <li class="clebex-item-section-item resources-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "outlook", "suggested-resources")
                }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator resources">
        <div
          v-for="suggestedResource in suggestedResourcesList"
          :key="suggestedResource.id"
          class="resource-wrapper"
        >
          <icon
            @click="handleResources(suggestedResource.email)"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
            :class="{ 'no-pointer-events': loading }"
          />
          <div class="resource-name" style="flex: 1">
            {{
              suggestedResource.translations.find((el) => el.locale === locale)
                ? suggestedResource.translations.find(
                    (el) => el.locale === locale
                  ).attributes.name
                : suggestedResource.name
            }},
            <span>{{
              suggestedResource.full_path
                .split(", ")
                .filter((el, i) => i > 0)
                .join(", ")
            }}</span>
            <!-- <span>5th floor, clebex sky tower</span> -->
          </div>
          <icon
            @click="openResourceInfo(suggestedResource.id)"
            icon="#cx-app1-information"
            width="20"
            height="20"
            style="transform: rotate(-45deg)"
          />
        </div>
      </li>
    </ul>
    <ul
      v-if="resources && resources.length"
      class="clebex-item-section tiny-top-spacing"
    >
      <li class="clebex-item-section-item resources-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "outlook", "resources-selected")
                }}
              </div>
            </span>
            <div
              v-if="!resources.find((el) => el.checker)"
              class="resources-warning-wrapper"
            >
              <icon
                class="error"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator resources">
        <div
          v-for="resource in resources"
          :key="resource.id"
          class="resource-wrapper"
          :class="{ disabled: !resource.checker }"
        >
          <icon
            @click="handleResources(resource.email)"
            class="error"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name" style="flex: 1">
            {{ resource.name }},
            <span>{{
              resource.full_path
                .split(", ")
                .filter((el, i) => i > 0)
                .join(", ")
            }}</span>
          </div>
          <icon
            @click="openResourceInfo(resource.id)"
            icon="#cx-app1-information"
            width="20"
            height="20"
          />
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import mapMixin from "@/services/mixins/map/map";
import httpServiceAuth, {
  getLang,
  getSubdomain,
} from "@/services/http-service";
import qs from "qs";
import dayjs from "dayjs";
import { apiEndpoints } from "@/services/constants";

export default {
  name: "AdvancedSearch",
  data() {
    return {
      selection: "resource",
      levelIds: [],
      resourceTypeIds: [],
      resourceAttributeIds: [],
      capacity: 1,
      selectedMapId: false,
    };
  },
  created() {
    this.getSuggestedResources({
      capacity: this.liveCapacity,
      datetime_from: this.start,
      datetime_to: this.end,
      resource_ids: this.resourceIds,
      recurrence: this.recurrence ? this.recurrence : {},
    }).then(() => {
      this.setLevelId();
    });
  },
  computed: {
    ...mapState("outlook", [
      "enhancedLocation",
      "resources",
      "suggestedResources",
      "requiredAttendees",
      "optionalAttendees",
      "loading",
      "start",
      "end",
      "recurrence",
      "map",
    ]),
    locale() {
      return getLang();
    },
    warningNumber() {
      if (this.resources.filter((el) => !el.checker).length) {
        return this.resources.filter((el) => !el.checker).length;
      }
      return null;
    },
    warningMessages() {
      if (this.resources.filter((el) => !el.checker).length) {
        let warnings = [];
        this.resources
          .filter((el) => !el.checker)
          .forEach((element) => {
            const warningIndex = warnings.findIndex(
              (el) => el.message === element.checker_message[0]
            );
            if (warningIndex !== -1) {
              warnings[warningIndex].resourceName = [
                ...warnings[warningIndex].resourceName,
                element.name,
              ];
            } else {
              warnings.push({
                message: element.checker_message[0],
                resourceName: [element.name],
              });
            }
          });
        return warnings;
      }
      return null;
    },
    payload() {
      return {
        level_ids: this.levelIds,
        resource_type_ids: this.resourceTypeIds,
        resource_attribute_ids: this.resourceAttributeIds,
        capacity: this.liveCapacity,
        datetime_from: this.start,
        datetime_to: this.end,
        resource_ids: this.resourceIds,
        recurrence: this.recurrence ? this.recurrence : {},
      };
    },
    suggestedResourcesList() {
      if (
        this.suggestedResources &&
        this.suggestedResources.resource_suggestions &&
        this.suggestedResources.resource_suggestions.length
      ) {
        return this.suggestedResources.resource_suggestions.filter(
          (el) =>
            !this.enhancedLocation
              .map((element) => element.locationIdentifier.id)
              .includes(el.email)
        );
      }
      return [];
    },
    liveCapacity() {
      let cap = parseInt(
        this.requiredAttendees.length +
          this.optionalAttendees.length +
          this.capacity
      );
      if (cap > 0) {
        return cap;
      } else {
        console.log(cap);
        return cap + 1;
      }
    },
    resourceIds() {
      return this.resources.map((el) => el.id);
    },
  },
  methods: {
    ...mapActions("outlook", [
      "setEnhancedLocation",
      "getSuggestedResources",
      "setMap",
    ]),
    onInputChange(event) {
      // Clear the previous timeout
      clearTimeout(this.timeout);

      // Set the new value for liveCapacity
      // this.liveCapacity = event.target.value;
      console.log(event.target.value);

      // Set a new timeout to call the method after 1 second
      this.timeout = setTimeout(() => {
        this.liveCapacity = event.target.value;
        this.capacity = event.target.value;
      }, 1000);
    },
    generateSlots(start, end) {
      const slots = [];
      let currentSlot = new Date(start);

      while (currentSlot < new Date(end)) {
        const nextSlot = new Date(currentSlot);
        nextSlot.setMinutes(currentSlot.getMinutes() + 15);
        slots.push({
          datetime_from: this.formatISOWithTimezone(currentSlot),
          datetime_to: this.formatISOWithTimezone(nextSlot),
        });
        currentSlot = nextSlot;
      }

      return slots;
    },
    async getRequiredSlots(date = null) {
      let dateForSlots = date ? date : dayjs().format("YYYY-MM-DD");
      const { $store } = this;
      $store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.slots}/default`, {
          params: {
            types: ["QUARTER_HOUR"],
            date: dateForSlots,
            timezone: dayjs.tz.guess(),
          },
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.show = false;
        });
    },
    setSelectedMapId() {
      this.selectedMapId = false;
      if (this.levelIds && this.levelIds.length) {
        if (this.levelIds.length == 1) {
          let sl = this.suggestedResources.levels.find(
            (l) => l.id == this.levelIds[0]
          );
          if (sl) {
            if (sl.map_id) {
              this.selectedMapId = sl.map_id;
            }
          }
        }
      }
    },
    handleResources(email) {
      if (this.loading) {
        return;
      }
      if (email) {
        const val = this.enhancedLocation.find(
          (el) => el.locationIdentifier.id === email
        );
        if (val) {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: JSON.parse(JSON.stringify(val.locationIdentifier)),
            },
            "*"
          );
        } else {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: { id: email, type: "room" },
              add: true,
            },
            "*"
          );
        }
      }
    },
    openResourceInfo(id) {
      this.$router.push({
        name: "r_outlook-advanced-search-resource-info",
        params: { resource_id: id },
      });
    },
    setLevelId(id) {
      if (this.loading) {
        return;
      }
      if (!id) {
        this.suggestedResources.levels.find((el) => el.is_default)
          ? this.levelIds.push(
              this.suggestedResources.levels.find((el) => el.is_default).id
            )
          : this.suggestedResources.levels.find((el) => el.is_favorite)
          ? this.levelIds.push(
              this.suggestedResources.levels.find((el) => el.is_favorite).id
            )
          : this.levelIds.push(this.suggestedResources.levels[0].id);
      } else {
        if (this.levelIds.includes(id)) {
          if (this.levelIds.length > 1) {
            this.levelIds = this.levelIds.filter(function(e) {
              return e !== id;
            });
          }
        } else {
          this.levelIds.push(id);
        }
      }
      this.setSelectedMapId();
    },
    setResourceTypeId(id) {
      if (this.resourceTypeIds.includes(id)) {
        this.resourceTypeIds = this.resourceTypeIds.filter(function(e) {
          return e !== id;
        });
      } else {
        this.resourceTypeIds.push(id);
      }
    },
    setResourceAttributeId(id) {
      if (this.resourceAttributeIds.includes(id)) {
        this.resourceAttributeIds = this.resourceAttributeIds.filter(function(
          e
        ) {
          return e !== id;
        });
      } else {
        this.resourceAttributeIds.push(id);
      }
    },
    increaseCapacity() {
      this.liveCapacity++;
      this.capacity++;
    },
    decreaseCapacity() {
      if (this.capacity >= 0) {
        this.liveCapacity--;
        this.capacity--;
      }
    },
    goTo5DMap() {
      const envVariables = process.env;
      const { VUE_APP_5DMAPS_URL } = envVariables;
      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

      const slots = {
        type: "QUARTER_HOUR",
        slots: this.generateSlots(this.start, this.end),
      };

      let outlookResources = "";

      if (this.resources) {
        this.resources.forEach((r) => {
          outlookResources += r.id + "-";
        });
      }

      if (outlookResources != "") {
        outlookResources = "&outlook_resources=" + outlookResources;
      }

      const slotsUrl = qs.stringify(slots);

      this.setMap(
        `${VUE_APP_5DMAPS_URL}/view/${
          this.selectedMapId
        }?api=${this.getMapApi()}&access_token=${localStorage.getItem(
          "atApp"
        )}&language=${getLang()}&mode=plan${subdomainUrl}&${slotsUrl}&outlook=yes${outlookResources}`
      );

      this.$router.push({
        name: "r_outlook-map",
      });
    },
    formatISOWithTimezone(dateObj) {
      // Extract the local time components
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(dateObj.getDate()).padStart(2, "0");
      const hours = String(dateObj.getHours()).padStart(2, "0");
      const minutes = String(dateObj.getMinutes()).padStart(2, "0");
      const seconds = String(dateObj.getSeconds()).padStart(2, "0");
      const milliseconds = String(dateObj.getMilliseconds()).padStart(3, "0");

      // Construct the ISO 8601 string in UTC with the same local time
      const isoDateStr = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

      return isoDateStr;
    },
  },
  watch: {
    payload: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.getSuggestedResources(this.payload);
        }
      },
    },
    resources: {
      deep: true,
      handler() {
        this.getSuggestedResources(this.payload);
      },
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  mixins: [mapMixin],
};
</script>
<style>
.declarable-icon {
  width: 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]:focus {
  outline: none !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
